import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  @font-face {
    font-family: 'Apricots';
    src: url('fonts/apricots/apricots.woff2') format('woff2'),
         url('fonts/apricots/apricots.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }


  @font-face {
      font-family: 'Apricots';
      src: url('fonts/apricots/apricots.woff2') format('woff2'),
          url('fonts/apricots/apricots.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }

  h1 {
    font-family: Apricots, cursive;
    font-size: 72px;
  }
`;
