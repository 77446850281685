import { httpsCallable } from 'firebase/functions';
import { useEffect } from 'react';
import { Container } from './App.style';
import { functions } from './firebase';

const helloWorld = httpsCallable(functions, 'helloWorld');

function App() {
  useEffect(() => {
    helloWorld().then(console.log).catch(console.error);
  }, []);

  return (
    <Container>
      <h1>Baby Tracker</h1>
    </Container>
  );
}

export default App;
