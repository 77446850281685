// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyDIShQUI9i9enfUG2IV-i3GDrVA2a-T5C8',
  authDomain: 'baby-tracker-dev-8f481.firebaseapp.com',
  projectId: 'baby-tracker-dev-8f481',
  storageBucket: 'baby-tracker-dev-8f481.appspot.com',
  messagingSenderId: '344741381229',
  appId: '1:344741381229:web:fb12338771271293c2eb64',
  measurementId: 'G-N9FGEPRLDY',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

export { app, analytics, functions };
