import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #373a7c;
  color: #ffffff;
  text-align: center;
  padding: 30px;
`;
